import {createContext, useMemo, useState, useEffect, useRef} from 'react';

const searchCategories = [
  {
    text: 'Invitations',
    urlFilterParam: 'invitation',
  },
  {
    text: 'eCards',
    urlFilterParam: 'card',
  },
];

const defaultContextValue = {
  searchQuery: '',
  setSearchQuery: () => {},
  searchCategories,
  searchCategory: 'Invitations',
  setSearchCategory: () => null,
  containerRef: {current: null},
};

export const SearchContext = createContext(defaultContextValue);

export const SearchContextParent = ({children}) => {
  const [mounted, setMounted] = useState(false);
  const [searchCategory, setSearchCategory] = useState('Invitations');
  const searchQuery = useRef('');
  const setSearchQuery = (val) => {
    searchQuery.current = val;
  };
  const containerRef = useRef(null);

  useEffect(() => {
    setMounted(true);
  }, []);

  const contextValue = useMemo(
    () => ({
      searchCategories,
      searchCategory,
      setSearchCategory,
      searchQuery,
      setSearchQuery,
      containerRef,
    }),
    [searchCategory, mounted]
  );

  return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>;
};
