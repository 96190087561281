import {useState, useEffect, useRef} from 'react';

export const isInViewport = (element, options = {}) => {
  const height = window.innerHeight || document.documentElement.clientHeight;
  const width = window.innerWidth || document.documentElement.clientWidth;
  const offset = {left: 0, right: 0, top: 0, bottom: 0, ...options};
  const rect = element.getBoundingClientRect();
  return (
    rect.right >= -offset.left &&
    rect.bottom >= -offset.top &&
    rect.left <= width + offset.right &&
    rect.top <= height + offset.bottom
  );
};

export const useShouldShowInlineSearch = () => {
  const mounted = useRef(false);
  const [show, setShow] = useState(true);

  useEffect(() => {
    mounted.current = true;

    if (window.location.pathname.startsWith('/event/')) {
      return false;
    }

    if (window.location.pathname === '/signup-sheets/') {
      return true;
    }

    const checkIsSearchBarInViewport = () => {
      if (!mounted.current) return;
      const searchContainer = document.querySelector('#inline-search');
      const megaNav = document.querySelector('#pbMegaNav');
      if (!megaNav) return;
      const megaNavBox = megaNav.getBoundingClientRect();
      if (!searchContainer) {
        // Inline search container not found; don't show inline
        setShow(false);
      } else {
        // Show if it's in view
        setShow(
          isInViewport(searchContainer, {
            left: 0,
            right: 0,
            bottom: 0,
            top: -megaNavBox.bottom,
          })
        );
      }
    };

    const debouncedCheckIsSearchBarInViewport = evite.debounce(checkIsSearchBarInViewport, 50);

    if (window.location.pathname !== '/') {
      // If not on homepage, show in meganav
      setShow(false);
    } else {
      // On homepage, determine whether or not to show it in meganav
      checkIsSearchBarInViewport();
      window.addEventListener('scroll', debouncedCheckIsSearchBarInViewport);
      window.addEventListener('resize', debouncedCheckIsSearchBarInViewport);
      return () => {
        mounted.current = false;
        window.removeEventListener('scroll', debouncedCheckIsSearchBarInViewport);
        window.removeEventListener('resize', debouncedCheckIsSearchBarInViewport);
      };
    }

    return undefined;
  }, []);

  return show;
};

export const getCategoryHref = (category) => {
  let href = category?.href || '';
  if (!href) {
    return `/gallery/category/${category.name}/?source_meganav&filter=${category.type}&cat=${category.id}`;
  }

  if (href.startsWith('/')) {
    href = `${window.location.protocol}//${window.location.hostname}${href}`;
  }
  const url = new URL(href);
  if (!url.searchParams.has('cat')) {
    url.searchParams.append('cat', category.id);
  }
  return url.toString();
};
