import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {PB_Chevron as ChevronIcon} from '~/common/svg/PB_Chevron';

import './MWExpandedCategoryView.sass';

export const MWExpandedCategoryView = ({
  categories,
  category_id: categoryId,
  category_names: categoryNames,
  display_name: displayName,
  name,
  visible,
  type,
  dataQaId,
  linkTabIndex,
  open,
  onToggle,
}) => {
  const [openOverride, setOpenOverride] = useState(false);

  const handleClick = (e) => {
    const {inVariation: is} = evite.experiments;

    if (is('dyo_uyo_landing', 'landing') && displayName.includes('design_your_own')) {
      e.preventDefault();
      window.location.href = '/upload-your-own';
    }
  };

  const renderCategories = (className) =>
    categories.map((c) => (
      <div key={c.name} className={className}>
        <a
          onClick={handleClick}
          href={
            c.href
              ? c.href
              : `/gallery/category/${c.name}/?source_meganav&filter=${type}&cat=${categoryId}`
          }
          className="mw-expanded-category-view__mw-nav-link"
          data-qa-id={`${c.name}_subcategoryLink`}
        >
          {c.display_name}
        </a>
      </div>
    ));

  useEffect(() => {
    if (!open) {
      setOpenOverride(false);
    }
  }, [open]);

  const toggle = (nameValue) => {
    if (onToggle) {
      onToggle(nameValue);
    } else {
      setOpenOverride(!openOverride);
    }
  };

  const isOpen = open || openOverride;

  return (
    <div key={name} className="mw-subcategory">
      {visible ? (
        <>
          <button
            className="unstyled-button expandable-subcategory"
            onClick={() => toggle(name)}
            tabIndex={linkTabIndex}
            data-qa-id={`${name}_toggle`}
          >
            <span className="mw-expanded-category-view__mw-nav-link" data-qa-id={dataQaId}>
              {categoryNames}
            </span>
            {categories.length > 0 ? (
              <ChevronIcon ratio={0.5} direction={isOpen ? 'down' : 'right'} />
            ) : null}
          </button>
          {isOpen ? renderCategories('expandable-subsubcategory') : null}
        </>
      ) : (
        renderCategories('expandable-subcategory')
      )}
    </div>
  );
};

MWExpandedCategoryView.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  category_id: PropTypes.string.isRequired,
  category_names: PropTypes.arrayOf(PropTypes.string),
  display_name: PropTypes.string,
  name: PropTypes.string,
  visible: PropTypes.bool,
  type: PropTypes.oneOf(['invitation', 'card']).isRequired,
  dataQaId: PropTypes.string.isRequired,
  linkTabIndex: PropTypes.number,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
};
