import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import parseHTML from 'html-react-parser';
import DOMPurify from 'dompurify';
import XDate from 'xdate';
import {Divider} from '~/common/_pb_components/atoms/Divider';
import {Link} from '~/common/_pb_components/atoms/Link';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PB_Chevron as ChevronIcon} from '~/common/svg/PB_Chevron';

import './NotificationsView.sass';

const createdAsDate = (created) => {
  const CREATED_REGEX = /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})\.(\d{3})/;
  const match = CREATED_REGEX.exec(created);
  const d = new XDate();
  if (match) {
    d.setUTCFullYear(match[1], parseInt(match[2], 10) - 1, match[3]);
    d.setUTCHours(match[4], match[5], match[6], match[7]);
  }
  return d;
};

const Notification = (props) => {
  const {
    created,
    id,
    onHideNotification,
    onMarkAsRead,
    onMarkAsUnread,
    read,
    re_user_image_url: reUserImgURL,
    re_user_initials: reUserInitials,
    text,
    view_details_url: viewDetailsURL,
  } = props;

  const [optionsMenuVisible, setOptionsMenuVisible] = useState(false);
  const [chevronHovered, setChevronHovered] = useState(false);
  const [userImageErr, setUserImageErr] = useState(false);
  const showOptions = () => setOptionsMenuVisible(true);
  const hideOptions = () => setOptionsMenuVisible(false);
  const showOptionsPopup = () => setChevronHovered(true);
  const hideOptionsPopup = () => setChevronHovered(false);

  useEffect(() => {
    const notification = document.getElementById(created);
    const optionsChevron = document.getElementById(`optionsChevron_${created}`);
    if (notification) {
      notification.addEventListener('mouseenter', showOptions);
      notification.addEventListener('mouseleave', hideOptions);
      optionsChevron.addEventListener('mouseenter', showOptionsPopup);
      optionsChevron.addEventListener('mouseleave', hideOptionsPopup);
      return () => {
        notification.removeEventListener('mouseenter', showOptions);
        notification.removeEventListener('mouseleave', hideOptions);
        optionsChevron.removeEventListener('mouseenter', showOptionsPopup);
        optionsChevron.removeEventListener('mouseleave', hideOptionsPopup);
      };
    }

    return undefined;
  }, []);

  return (
    <a
      className={`pb-notification ${!read ? 'unread' : ''}`}
      href={viewDetailsURL}
      id={id}
      data-qa-id={`notification_${id}`}
    >
      <div className="nav-avatar">
        {!!reUserImgURL && !userImageErr ? (
          <img src={reUserImgURL} alt="Sender profile" onError={() => setUserImageErr(true)} />
        ) : (
          <Typography as="p" variant="paragraph4" data-qa-id={`notificationSenderInitials_${id}`}>
            {reUserInitials}
          </Typography>
        )}
      </div>
      <div className="notification-details">
        <Typography as="p" variant="paragraph3" data-qa-id={`notificationDetails_${id}`}>
          {parseHTML(DOMPurify.sanitize(text))}
        </Typography>
        <Typography as="p" variant="paragraph4" data-qa-id={`notificationCreatedDate_${id}`}>
          {createdAsDate(created).toString('MMMM d, yyyy')}
        </Typography>
      </div>
      <div className="option-elements-wrapper">
        <div id={`optionsChevron_${created}`}>
          <button
            className={`unstyled-button options-chevron ${
              optionsMenuVisible ? 'visible' : 'hidden'
            }`}
            data-qa-id={`notificationOptions_${id}`}
            aria-label="options element"
            type="button"
          >
            <ChevronIcon ratio={0.5} color="#92959B" />
          </button>
          {chevronHovered && (
            <button
              type="button"
              className="unstyled-button hide-notification-popup"
              onClick={(e) => onHideNotification(e, created)}
              data-qa-id={`hideNotificationButton_${id}`}
            >
              <p className="link small">Hide this notification</p>
            </button>
          )}
        </div>
        <button
          type="button"
          aria-label="mark as read or unread"
          className={`unstyled-button ${optionsMenuVisible ? 'visible' : 'hidden'}`}
          onClick={(e) => {
            e.stopPropagation();
            if (read) {
              onMarkAsUnread(e, created);
            } else {
              onMarkAsRead(e, created);
            }
          }}
          data-qa-id={`notificationMarkAs${read ? 'Unread' : 'Read'}Button_${id}`}
        >
          <div className={`notification-dot ${read ? 'read' : 'unread'}`} />
        </button>
      </div>
    </a>
  );
};

export const NotificationsView = ({onMarkAllAsRead, ...props}) => (
  <div className="notifications-view">
    <div className="notifications-view__header">
      <p className="paragraph3 notifications-view__title">Your Evite notifications</p>
      <button
        type="button"
        className="unstyled-button paragraph3 link"
        onClick={onMarkAllAsRead}
        data-qa-id="notificationsView_markAllAsReadButton"
      >
        Mark all as read
      </button>
    </div>
    <Divider marginBottom="-0.5rem" />
    <div className="notifications-view__notifications">
      {props.notifications && props.notifications.length > 0 ? (
        props.notifications.map((n, i) => (
          <React.Fragment key={`notification_${i + 1}`}>
            <Notification
              {...n}
              id={n.created}
              onMarkAsUnread={props.onMarkOneAsUnread}
              onMarkAsRead={props.onMarkOneAsRead}
              onHideNotification={props.onHideNotification}
            />
            {i < props.notifications.length - 1 && (
              <Divider marginTop="-0.5rem" marginBottom="-0.5rem" />
            )}
          </React.Fragment>
        ))
      ) : (
        <div className="notifications-view__empty">
          <p className="paragraph2">
            Find all your notifications here and stay up to date on your Evite events!
          </p>
        </div>
      )}
    </div>
    <Divider marginTop="-0.5rem" />
    <div className="notifications-view__view-all-link-wrapper">
      <Link href="/all-notifications" variant="large" data-qa-id="viewAllNotificationsLink">
        View all notifications
      </Link>
    </div>
  </div>
);

NotificationsView.propTypes = {
  unread: PropTypes.number.isRequired,
  unviewed: PropTypes.number.isRequired,
  onMarkAllAsRead: PropTypes.func,
  onMarkOneAsUnread: PropTypes.func,
  onMarkOneAsRead: PropTypes.func,
  onHideNotification: PropTypes.func,
};
